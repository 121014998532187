import React, {Component} from "react";
import overviewA from '../../images/maps/de_vertigo/overviews/Overview-A.jpg';
import overviewB from '../../images/maps/de_vertigo/overviews/Overview-B.jpg';
import { Link } from "react-router-dom";

class DeVertigo extends Component<any, any>{
    render() {
        return (
            <div className="content">
                <figure className="overview">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={overviewA}/>
                        <Link to={'/image/de_vertigo/smokes/A_T_Site.jpg'}>
                            <circle className="smoke" cx="1525" cy="465" r="32"/>
                        </Link>
                    </svg>
                </figure>

                <figure className="overview">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={overviewB}/>
                        <Link to={'/image/de_vertigo/smokes/B_T_CT.jpg'}>
                            <circle className="smoke" cx="1070" cy="545" r="32"/>
                        </Link>
                        <Link to={'/image/de_vertigo/smokes/B_T_Spot-right.jpg'}>
                            <circle className="smoke" cx="1070" cy="1070" r="32"/>
                        </Link>
                        <Link to={'/image/de_vertigo/smokes/B_T_CT-Mid-to-B.jpg'}>
                            <circle className="smoke" cx="1300" cy="350" r="32"/>
                        </Link>
                        <Link to={'/image/de_vertigo/smokes/B_T_CT-Mid-to-A.jpg'}>
                            <circle className="smoke" cx="1500" cy="350" r="32"/>
                        </Link>
                        <Link to={'/image/de_vertigo/smokes/B_T_Window.jpg'}>
                            <circle className="smoke" cx="1290" cy="500" r="32"/>
                        </Link>
                    </svg>
                </figure>
            </div>
        );
    }
}

export default DeVertigo;
