import React, {useEffect, useState} from "react";
import TestList from "./TestList";
import Button from "@mui/material/Button";
import {Nade} from "./NadeModel";
import NadeList from "./NadeList";

export default function TestArea()
{
    const [nades, setNades] = useState<Nade[]>([]);

    const fetchNades = async () => {
        fetch('/api/nades', {
            method: 'GET',
            // mode: 'no-cors',
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(res => {
                return res.json();
            })
            .then(data => {
                setNades(data);
                console.log(data);
            })
    };

    useEffect(() => {
        fetchNades();
        console.log("CURRY");
    }, []);

    const handleClick = async () =>
    {
        await fetchNades();
    };

    return(
        <div className={"test-area"}>
            <div>This is the test Area</div>
            <Button variant={"contained"} onClick={handleClick}>Fetch Nades</Button>
            <ul>
                {nades.map(nade => (
                    <li key={nade._id}>{nade.name} | {nade.type} | {nade.nr} | {nade._id}</li>
                    // <li>{nade['name']} | {nade['type']} | {nade['nr']} | {nade['_id']}</li>
                ))}
            </ul>
            <NadeList nades={nades}/>
            <TestList />
        </div>
    )
};
