import React, {Component} from "react";
import overviewA from '../../images/maps/de_inferno/overviews/Overview-A.jpg';
import overviewB from '../../images/maps/de_inferno/overviews/Overview-B.jpg';
import { Link } from "react-router-dom";

class DeInferno extends Component<any, any>{
    render() {
        return (
            <div className="content">
                <figure className="overview">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={overviewA}/>
                        <Link to={'/image/de_inferno/smokes/MID_T_Long-Library.jpg'}>
                            <circle className="smoke" cx="1570" cy="384" r="32"/>
                        </Link>
                        <Link to={'/image/de_inferno/smokes/MID_T_Mid-Short.jpg'}>
                            <circle className="smoke" cx="715" cy="934" r="32"/>
                        </Link>
                        <Link to={'/image/de_inferno/smokes/MID_T_CT-Arch.jpg'}>
                            <circle className="smoke" cx="1119" cy="239" r="32"/>
                        </Link>
                        <Link to={'/image/de_inferno/smokes/MID_T_Mid-Smoke.jpg'}>
                            <circle className="smoke" cx="490" cy="750" r="32"/>
                        </Link>
                        <Link to={'/image/de_inferno/smokes/MID_T_Pit.jpg'}>
                            <circle className="smoke" cx="1991" cy="974" r="32"/>
                        </Link>
                        <Link to={'/image/de_inferno/smokes/A_T_Long.jpg'}>
                            <circle className="smoke" cx="760" cy="577" r="32"/>
                        </Link>
                    </svg>
                </figure>
                <figure className="overview">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={overviewB}/>
                        <Link to={'/image/de_inferno/smokes/B_Coffins.jpg'}>
                            <circle className="smoke" cx="1352" cy="474" r="32"/>
                        </Link>
                        <Link to={'/image/de_inferno/smokes/B_CT.jpg'}>
                            <circle className="smoke" cx="1815" cy="1099" r="32"/>
                        </Link>
                        <Link to={'/image/de_inferno/smokes/B_CT_Banana-Close.jpg'}>
                            <circle className="smoke" cx="1250" cy="1386" r="32"/>
                        </Link>
                        <Link to={'/image/de_inferno/smokes/B_CT_Banana-Sneaky.jpg'}>
                            <circle className="smoke" cx="93" cy="1386" r="32"/>
                        </Link>
                        <Link to={'/image/de_inferno/smokes/B_CT_Site.jpg'}>
                            <circle className="smoke" cx="1170" cy="838" r="32"/>
                        </Link>
                        <Link to={'/image/de_inferno/smokes/B_T_Site.jpg'}>
                            <circle className="smoke" cx="1110" cy="898" r="32"/>
                        </Link>
                    </svg>
                </figure>
            </div>
        );
    }
}

export default DeInferno;
