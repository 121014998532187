import React, {Component} from 'react';
import '../css/mystyle.scss';

class About extends Component<any, any>{
    render() {
        return (
            <div className="Welcome">
                <header className="Welcome-header">
                    <div className="overlay">
                        <div className="description">
                            <h1>About!</h1>
                            <p>
                                This is some text.
                            </p>
                            <button className="">Tell Me More!</button>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
}

export default About;
