import React, {Component} from "react";
import overviewA from '../../images/maps/de_train/overviews/Overview-A.jpg';
import overviewB from '../../images/maps/de_train/overviews/Overview-B.jpg';
import { Link } from "react-router-dom";

class DeTrain extends Component<any, any>{
    render() {
        return (
            <div className="content">
                <figure className="overview">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={overviewA}/>
                        <Link to={'/image/de_train/smokes/A_T_Oldbomb.jpg'}>
                            <circle className="smoke" cx="1184" cy="253" r="32"/>
                        </Link>
                        <Link to={'/image/de_train/smokes/A_T_A3.jpg'}>
                            <circle className="smoke" cx="781" cy="325" r="32"/>
                        </Link>
                        <Link to={'/image/de_train/smokes/A_T_A3-from-ivy.jpg'}>
                            <circle className="smoke" cx="684" cy="325" r="32"/>
                        </Link>
                        <Link to={'/image/de_train/smokes/A_T_Between-Red-Bombtrain.jpg'}>
                            <circle className="smoke" cx="1300" cy="500" r="32"/>
                        </Link>
                        <Link to={'/image/de_train/smokes/A_T_Ebox.jpg'}>
                            <circle className="smoke" cx="1583" cy="860" r="32"/>
                        </Link>
                        <Link to={'/image/de_train/smokes/A_T_Between-Blue-Red.jpg'}>
                            <circle className="smoke" cx="1210" cy="814" r="32"/>
                        </Link>
                        <Link to={'/image/de_train/smokes/A_T_Olof.jpg'}>
                            <circle className="smoke" cx="915" cy="989" r="32"/>
                        </Link>
                        <Link to={'/image/de_train/smokes/A_T_ZConnector.jpg'}>
                            <circle className="smoke" cx="1462" cy="360" r="32"/>
                        </Link>
                        <Link to={'/image/de_train/smokes/A_T_Between-Green-Red.jpg'}>
                            <circle className="smoke" cx="1025" cy="564" r="32"/>
                        </Link>
                    </svg>
                </figure>

                <figure className="overview">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={overviewB}/>
                        <Link to={'/image/de_train/smokes/B_T_Summit.jpg'}>
                            <circle className="smoke" cx="1092" cy="508" r="32"/>
                        </Link>
                        <Link to={'/image/de_train/smokes/B_T_Catwalk-protect.jpg'}>
                            <circle className="smoke" cx="566" cy="295" r="32"/>
                        </Link>
                        <Link to={'/image/de_train/smokes/B_T_Between-red-yellow.jpg'}>
                            <circle className="smoke" cx="1275" cy="934" r="32"/>
                        </Link>
                        <Link to={'/image/de_train/smokes/B_T_Ramp-protect.jpg'}>
                            <circle className="smoke" cx="1235" cy="359" r="32"/>
                        </Link>
                        <Link to={'/image/de_train/smokes/B_T_ZConnector.jpg'}>
                            <circle className="smoke" cx="2162" cy="883" r="32"/>
                        </Link>
                    </svg>
                </figure>
            </div>
        );
    }
}

export default DeTrain;
