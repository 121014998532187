import React, {Component} from "react";
import overviewA from '../../images/maps/de_cache/overviews/Overview-A.jpg';
import overviewMid from '../../images/maps/de_cache/overviews/Overview-MID.jpg';
import overviewB from '../../images/maps/de_cache/overviews/Overview-B.jpg';
import { Link } from "react-router-dom";

class DeCache extends Component<any, any>{
    render() {
        return (
            <div className="content">
                <figure className="overview">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={overviewA} />
                        <Link to={'/image/de_cache/smokes/A_CT_Main.jpg'}>
                            <circle className="smoke" cx="946" cy="575" r="32"/>
                        </Link>
                        <Link to={'/image/de_cache/smokes/A_T_Site.jpg'}>
                            <circle className="smoke" cx="1337" cy="647" r="32"/>
                        </Link>
                    </svg>
                </figure>

                <figure className="overview">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={overviewMid} />
                        <Link to={'/image/de_cache/smokes/MID_T_Connector.jpg'}>
                            <circle className="smoke" cx="350" cy="550" r="32"/>
                        </Link>
                    </svg>
                </figure>

                <figure className="overview">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={overviewB}/>
                        <Link to={'/image/de_cache/smokes/B_T_Hell.jpg'}>
                            <circle className="smoke" cx="600" cy="800" r="32"/>
                        </Link>
                    </svg>
                </figure>
            </div>
        );
    }
}

export default DeCache;
