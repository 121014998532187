import React from "react";
import TextField from "@mui/material/TextField";
import {Box, Theme, useTheme} from "@mui/material";
import Button from "@mui/material/Button";

export function Login() {
    // ThemeProvider is a parent component which provides his children with a theme.
    // The theme can be retrieved by "useTheme()" hook.
    const theme: Theme = useTheme();

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Box component="form" noValidate autoComplete="off"
                sx={{
                    '& .MuiTextField-root':
                        {
                            margin: theme.spacing(2),
                            width: '25ch'
                        }
                }}
            >
                <TextField id="outlined-basic" label="Name" variant="outlined"/>
                <TextField id="outlined-basic" label="Password" variant="outlined" type="password"/>
                <Button
                    onClick={() => alert("ok")}
                    variant="contained"
                    color="primary"
                    size="large"
                    className={'myButton'}
                    style={{margin: '10px'}}
                >
                    Log in
                </Button>
            </Box>
        </Box>
    )
}
