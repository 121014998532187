import React from "react";
import BackButton from "./BackButton";
import {useParams} from "react-router-dom";

function LineupImage () {
    let { map, type, id} = useParams();

        return (
            <div className="content" style={{color: '#fff'}}>
                <BackButton/>
                <figure className={'singleImage'}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={require('../images/maps/' + map + '/' + type + '/' + id)}/>
                    </svg>
                </figure>
            </div>
        );
}

export default LineupImage;
