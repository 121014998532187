import React, {useState} from "react";
import NadeListItem from "./NadeListItem";

export default function NadeList({nades})
{
    const [] = useState()

    let itemArray: JSX.Element[] = [];

    for (let i: number = 0; i < nades.length; i++)
    {
        itemArray.push(<NadeListItem key={i} nade={nades[i]}/>);
    }

    return (
        <div className={"test-list"}>
            {itemArray}
        </div>
    );
}
