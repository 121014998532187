import React from 'react';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import './css/mystyle.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {
    createTheme ,
    ThemeProvider,
} from '@mui/material';
import lightGreen from "@mui/material/colors/lightGreen";
import {createRoot} from "react-dom/client";

const myCustomMuiTheme = createTheme ({
    palette: {
        mode: 'dark',
        primary: lightGreen,
    },
});


const container = document.getElementById('root');
const root = createRoot(container!);
root.render (
  <React.StrictMode>
      <ThemeProvider theme={myCustomMuiTheme}>
          {/*<BrowserRouter basename={'/csgo/utility'}>*/}
          <BrowserRouter>
              <App />
          </BrowserRouter>
      </ThemeProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
