import React, {Component} from "react";
import Navigation from "../Navigation";

class Header extends Component<any, any>{
    render() {
        return (
            <header className="sticky-top">
                <Navigation />
            </header>
        );
    }
}

export default Header;
