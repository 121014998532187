import React, {Component} from "react";
import overviewA from '../../images/maps/de_mirage/overviews/Overview-A.jpg';
import overviewMid from '../../images/maps/de_mirage/overviews/Overview-MID.jpg';
import overviewB from '../../images/maps/de_mirage/overviews/Overview-B.jpg';
import { Link } from "react-router-dom";

class DeMirage extends Component<any, any>{
    render() {
        return (
            <div className="content">
                <figure className="overview">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={overviewA}/>
                        <Link to={'/image/de_mirage/smokes/A_T_CT.jpg'}>
                            <circle className="smoke" cx="1200" cy="369" r="32"/>
                        </Link>
                        <Link to={'/image/de_mirage/smokes/A_T_Jungle.jpg'}>
                            <circle className="smoke" cx="1805" cy="595" r="32"/>
                        </Link>
                        <Link to={'/image/de_mirage/smokes/A_T_Stairs.jpg'}>
                            <circle className="smoke" cx="1865" cy="720" r="32"/>
                        </Link>
                    </svg>
                </figure>

                <figure className="overview">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={overviewMid}/>
                        <Link to={'/image/de_mirage/smokes/MID_T_Window.jpg'}>
                            <circle className="smoke" cx="1250" cy="310" r="32"/>
                        </Link>
                        <Link to={'/image/de_mirage/smokes/MID_T_Safemid.jpg'}>
                            <circle className="smoke" cx="1432" cy="720" r="32"/>
                        </Link>
                    </svg>
                </figure>

                <figure className="overview">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={overviewB}/>
                        <Link to={'/image/de_mirage/smokes/B_T_Bench.jpg'}>
                            <circle className="smoke" cx="603" cy="854" r="32"/>
                        </Link>
                        <Link to={'/image/de_mirage/smokes/B_T_Site.jpg'}>
                            <circle className="smoke" cx="1200" cy="914" r="32"/>
                        </Link>
                        <Link to={'/image/de_mirage/smokes/B_T_Kitchen-Door.jpg'}>
                            <circle className="smoke" cx="785" cy="1304" r="32"/>
                        </Link>
                        <Link to={'/image/de_mirage/smokes/B_T_Kitchen-Window.jpg'}>
                            <circle className="smoke" cx="1610" cy="1410" r="32"/>
                        </Link>
                    </svg>
                </figure>
            </div>
        );
    }
}

export default DeMirage;
