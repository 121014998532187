import React, {Component} from "react";
import overviewA from '../../images/maps/de_nuke/overviews/Overview-A.jpg';
import overviewMid from '../../images/maps/de_nuke/overviews/Overview-MID.jpg';
import { Link } from "react-router-dom";

class DeNuke extends Component<any, any>{
    render() {
        return (
            <div className="content">
                <figure className="overview">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={overviewA}/>
                        <Link to={'/image/de_nuke/smokes/A_T_Main.jpg'}>
                            <circle className="smoke" cx="241" cy="450" r="32"/>
                        </Link>
                        <Link to={'/image/de_nuke/smokes/A_T_Heaven.jpg'}>
                            <circle className="smoke" cx="1800" cy="655" r="32"/>
                        </Link>
                    </svg>
                </figure>

                <figure className="overview">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={overviewMid}/>
                        <Link to={'/image/de_nuke/smokes/MID_T_CT-Red.jpg'}>
                            <circle className="smoke" cx="1600" cy="550" r="32"/>
                        </Link>
                        <Link to={'/image/de_nuke/smokes/MID_T_Garage.jpg'}>
                            <circle className="smoke" cx="1950" cy="640" r="32"/>
                        </Link>
                        <Link to={'/image/de_nuke/smokes/MID_T_Garage-2.jpg'}>
                            <circle className="smoke" cx="1870" cy="670" r="32"/>
                        </Link>
                        <Link to={'/image/de_nuke/smokes/MID_T_Outside-second.jpg'}>
                            <circle className="smoke" cx="1700" cy="950" r="32"/>
                        </Link>
                        <Link to={'/image/de_nuke/smokes/MID_T_Outside-first.jpg'}>
                            <circle className="smoke" cx="1900" cy="950" r="32"/>
                        </Link>
                    </svg>
                </figure>
            </div>
        );
    }
}

export default DeNuke;
