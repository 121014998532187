import React, {Component, useState} from "react";
import TestListItem from "./TestListItem";

export default function TestList()
{
    const [stateVar] = useState({
        num: 3,
        name: "Curry",
        type: "smoke"
    })

    let itemArray: JSX.Element[] = [];

    for (let i: number = 0; i < 10; i++)
    {
        itemArray.push(<TestListItem key={i} propItem={{name: "Testnamr", type: "smoke", num: Math.random(), id: i}}/>);
    }

    return (
        <div className={"test-list"}>
            {itemArray}
        </div>
    );
}
