import React, {useState} from "react";
import Button from "@mui/material/Button";
import {IconButton} from "@mui/material";
import {DeleteOutline} from "@mui/icons-material";

export default function TestListItem({propItem})
{
    const [col, setCol] = useState("white");

    return (
        <div className={"test-list-row"}>
            <div>
                {propItem.id} | {propItem.name} | {propItem.type} | {propItem.num} | <span style={{color: col}}>color</span>
            </div>
            <div>
                <Button variant="text" onClick={() => setCol("blue")}>Blue</Button>
            </div>
            <div>
                <Button variant="text" onClick={() => setCol("red")}>red</Button>
            </div>
            <IconButton aria-label="delete">
                <DeleteOutline />
            </IconButton>
        </div>
    );
}
