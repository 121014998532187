import React from 'react';
import { Route, Routes } from "react-router-dom";

// styles
import './css/App.scss';

// Components
import Welcome from "./components/Welcome";
import Footer from "./components/Footer";
import Header from "./components/Header";
import About from "./components/About";
import LineupImage from "./components/LineupImage";
import DeDust2 from "./components/maps/de_dust2";
import DeCache from "./components/maps/de_cache";
import DeInferno from "./components/maps/de_inferno";
import DeMirage from "./components/maps/de_mirage";
import DeNuke from "./components/maps/de_nuke";
import DeOverpass from "./components/maps/de_overpass";
import DeTrain from "./components/maps/de_train";
import DeVertigo from "./components/maps/de_vertigo";
import {Login} from "./components/login";
import TestListItem from "./components/experiments/TestListItem";
import TestList from "./components/experiments/TestList";
import TestArea from "./components/experiments/TestArea";

function App() {
    return (
        <div className="App">
            <Header/>
                <Routes>
                    <Route path="/" element={<Welcome />}/>
                    <Route path="/about" element={<About />} />
                    <Route path="/tests" element={<TestArea />} />
                    <Route path="/admin/login" element={<Login />}/>
                    <Route path="/image/:map/:type/:id" element={<LineupImage />} />
                    <Route path="/map/:map/image/:id+" element={<LineupImage />} />
                    <Route path="/maps/de_dust2" element={<DeDust2 />} />
                    <Route path="/maps/de_cache" element={<DeCache />} />
                    <Route path="/maps/de_inferno" element={<DeInferno />} />
                    <Route path="/maps/de_mirage" element={<DeMirage />} />
                    <Route path="/maps/de_nuke" element={<DeNuke />} />
                    <Route path="/maps/de_overpass" element={<DeOverpass />} />
                    <Route path="/maps/de_train" element={<DeTrain />} />
                    <Route path="/maps/de_vertigo" element={<DeVertigo />} />
                </Routes>
            <Footer/>
        </div>
    );
}

export default App;
