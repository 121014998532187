import React, {Component} from 'react';
import '../css/mystyle.scss';

class Welcome extends Component<any, any>{
    render() {
        return (
            <div className="Welcome">
                <header className="Welcome-header">
                    <div className="overlay">
                        <div className="description">
                            <h1>Welcome to "CSGO Remember Utility Fast" guide!</h1>
                            <p>
                                In this guide you will find an interactive and fast way to remember different utility usages on different maps
                                in CSGO.
                                Click on the map and the spot you want to use your utility on. In the bottom left corner you'll see the position
                                you have to stand in.
                                The main screenshot shows where to aim.
                            </p>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
}

export default Welcome;
