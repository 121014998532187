import React from "react";
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function BackButton() {
    let navigate = useNavigate();

    return (
        <Button
            onClick={() => navigate(-1)}
            variant="contained"
            color="primary"
            startIcon={<ArrowBackIcon />}
            size="large"
            className={'myButton'}
            style={{margin: '10px'}}
        >
            Back
        </Button>
    )
}

export default BackButton;
