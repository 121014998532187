import React, {Component} from "react";
import overviewA from '../../images/maps/de_dust2/overviews/Overview-A.jpg';
import overviewMid from '../../images/maps/de_dust2/overviews/Overview-MID.jpg';
import overviewB from '../../images/maps/de_dust2/overviews/Overview-B.jpg';
import { Link } from "react-router-dom";

class DeDust2 extends Component<any, any>{
    render() {
        return (
            <div className="content">
                <figure className="overview">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={overviewA}/>
                        <Link to={'/image/de_dust2/smokes/A_T_CT-Base-deep.jpg'}>
                            <circle className="smoke" cx="1575" cy="575" r="32"/>
                        </Link>
                        <Link to={'/image/de_dust2/smokes/A_T_CT-Base-deep-from-short.jpg'}>
                            <circle className="smoke" cx="1654" cy="605" r="32"/>
                        </Link>
                        <Link to={'/image/de_dust2/smokes/A_T_CT-Base.jpg'}>
                            <circle className="smoke" cx="1075" cy="674" r="32"/>
                        </Link>
                        <Link to={'/image/de_dust2/smokes/A_T_Short.jpg'}>
                            <circle className="smoke" cx="1711" cy="486" r="32"/>
                        </Link>
                    </svg>
                </figure>

                <figure className="overview">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={overviewMid}/>
                        <Link to={'/image/de_dust2/smokes/MID_Mid-to-B.jpg'}>
                            <circle className="smoke" cx="826" cy="751" r="32"/>
                        </Link>
                        <Link to={'/image/de_dust2/smokes/MID_T_CT-Base-Box.jpg'}>
                            <circle className="smoke" cx="552" cy="615" r="32"/>
                        </Link>
                        <Link to={'/image/de_dust2/smokes/MID_CT_Mid-near-xbox.jpg'}>
                            <circle className="smoke" cx="1995" cy="600" r="32"/>
                        </Link>
                        <Link to={'/image/de_dust2/smokes/MID_T_xbox-from-tunnels.jpg'} title="from tunnel">
                            <circle className="smoke" cx="1835" cy="425" r="32"/>
                        </Link>
                        <Link to={'/image/de_dust2/smokes/MID_T_xbox-from-spawn.jpg'} title="from spawn">
                            <circle className="smoke" cx="1930" cy="425" r="32"/>
                        </Link>
                    </svg>
                </figure>

                <figure className="overview">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={overviewB}/>
                        <Link to={'/image/de_dust2/smokes/B_T_Site.jpg'}>
                            <circle className="smoke" cx="1204" cy="410" r="32"/>
                        </Link>
                        <Link to={'/image/de_dust2/smokes/B_T_Doors.jpg'}>
                            <circle className="smoke" cx="1530" cy="439" r="32"/>
                        </Link>
                        <Link to={'/image/de_dust2/smokes/B_CT_Tunnels-from-outside.jpg'}>
                            <circle className="smoke" cx="1665" cy="1271" r="32"/>
                        </Link>
                    </svg>
                </figure>
            </div>
        );
    }
}

export default DeDust2;
