import React, {Component} from "react";

class Footer extends Component {
    render() {
        return (
            <footer className="page-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12">
                            <h6 className="text-uppercase font-weight-bold">Additional Information</h6>
                            <p>This guide was created as an unpaid personal project. Don't expect anything. It is for personal use only.</p>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <h6 className="text-uppercase font-weight-bold">Contact</h6>
                            <p>Visit my <a href="https://steamcommunity.com/id/n0tinus3/">Steamprofile</a></p>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright text-center">© 2020 Copyright: Alex Meier</div>
            </footer>
        );
    }
}

export default Footer;
