import React, {useState} from "react";
import {Link} from "react-router-dom";

export default function Navigation () {

    const [menu, setMenu] = useState(false);

    return (
        <nav className="navbar navbar-expand-md">
            <a className="navbar-brand" href="https://steamcommunity.com/id/n0tinus3/"
               target="_blank"
               rel="noopener noreferrer"
               style={{textAlign: "center"}}>
                <img srcSet={require('./images/avatar-steam-profile.jpg')} style={{width: "60px"}} alt={''}/><br/>
                    ✰ n0tinus3 ✰
            </a>
            <button className="navbar-toggler navbar-dark" type="button" onClick={() => setMenu(!menu)}>
                <span className="navbar-toggler-icon"/>
            </button>
            <div className={`collapse navbar-collapse ${menu ? "show": ""}`} id="main-navigation">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <Link to={"/"} className="nav-link">Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={"/admin/login"} className="nav-link">Admin</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={"/tests"} className="nav-link">Tests</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={'/maps/de_cache'} className="nav-link">de_cache</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={"/maps/de_dust2"} className="nav-link">de_dust2</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={"/maps/de_inferno"} className="nav-link">de_inferno</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={"/maps/de_mirage"} className="nav-link">de_mirage</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={"/maps/de_nuke"} className="nav-link">de_nuke</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={"/maps/de_overpass"} className="nav-link">de_overpass</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={"/maps/de_train"} className="nav-link">de_train</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={"/maps/de_vertigo"} className="nav-link">de_vertigo</Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
}
