import React, {Component} from "react";
import overviewA from '../../images/maps/de_overpass/overviews/Overview-A.jpg';
import overviewMid from '../../images/maps/de_overpass/overviews/Overview-MID.jpg';
import overviewB from '../../images/maps/de_overpass/overviews/Overview-B.jpg';
import { Link } from "react-router-dom";

class DeOverpass extends Component<any, any>{
    render() {
        return (
            <div className="content">
                <figure className="overview">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={overviewA}/>
                        <Link to={'/image/de_overpass/smokes/A_T_Bank.jpg'}>
                            <circle className="smoke" cx="905" cy="586" r="32"/>
                        </Link>
                        <Link to={'/image/de_overpass/smokes/A_T_Spot-Left.jpg'}>
                            <circle className="smoke" cx="1083" cy="720" r="32"/>
                        </Link>
                        <Link to={'/image/de_overpass/smokes/A_T_Spot-Right.jpg'}>
                            <circle className="smoke" cx="1479" cy="616" r="32"/>
                        </Link>
                        <Link to={'/image/de_overpass/smokes/A_T_Stairs.jpg'}>
                            <circle className="smoke" cx="1250" cy="456" r="32"/>
                        </Link>
                        <Link to={'/image/de_overpass/smokes/A_T_Truck.jpg'}>
                            <circle className="smoke" cx="1509" cy="496" r="32"/>
                        </Link>
                    </svg>
                </figure>

                <figure className="overview">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={overviewMid}/>
                        <Link to={'/image/de_overpass/smokes/A_T_Restrooms.jpg'}>
                            <circle className="smoke" cx="651" cy="980" r="32"/>
                        </Link>
                        <Link to={'/image/de_overpass/smokes/A_T_Short.jpg'}>
                            <circle className="smoke" cx="530" cy="630" r="32"/>
                        </Link>
                    </svg>
                </figure>

                <figure className="overview">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2560 1440"
                         preserveAspectRatio="xMinYMin meet">
                        <image xlinkHref={overviewB}/>
                        <Link to={'/image/de_overpass/smokes/B_CT_CT-Monster.jpg'}>
                            <circle className="smoke" cx="1747" cy="486" r="32"/>
                        </Link>
                        <Link to={'/image/de_overpass/smokes/B_CT_CT-Short-Tunnel.jpg'}>
                            <circle className="smoke" cx="2089" cy="1245" r="32"/>
                        </Link>
                        <Link to={'/image/de_overpass/smokes/B_CT_Walkway-Short-Tunnel.jpg'}>
                            <circle className="smoke" cx="2029" cy="1215" r="32"/>
                        </Link>
                        <Link to={'/image/de_overpass/smokes/B_CT_Sandbags.jpg'}>
                            <circle className="smoke" cx="1552" cy="690" r="32"/>
                        </Link>
                        <Link to={'/image/de_overpass/smokes/B_T_Balcony.jpg'}>
                            <circle className="smoke" cx="107" cy="486" r="32"/>
                        </Link>
                        <Link to={'/image/de_overpass/smokes/B_T_Barrels.jpg'}>
                            <circle className="smoke" cx="1023" cy="426" r="32"/>
                        </Link>
                        <Link to={'/image/de_overpass/smokes/B_T_Bridge.jpg'}>
                            <circle className="smoke" cx="993" cy="750" r="32"/>
                        </Link>
                        <Link to={'/image/de_overpass/smokes/B_T_Bridge-2.jpg'}>
                            <circle className="smoke" cx="1083" cy="720" r="32"/>
                        </Link>
                        <Link to={'/image/de_overpass/smokes/B_T_B-Site.jpg'}>
                            <circle className="smoke" cx="1113" cy="586" r="32"/>
                        </Link>
                        <Link to={'/image/de_overpass/smokes/B_T_Pillar-Left-Site.jpg'}>
                            <circle className="smoke" cx="1023" cy="556" r="32"/>
                        </Link>
                        <Link to={'/image/de_overpass/smokes/B_T_Pillar-Right.jpg'}>
                            <circle className="smoke" cx="1250" cy="516" r="32"/>
                        </Link>
                    </svg>
                </figure>
            </div>
        );
    }
}

export default DeOverpass;
